import React, { useEffect, useRef } from 'react';
import Marquee from 'react-fast-marquee'
import nosotrosimg2 from '../icons/personajeNosotros.svg'
import { FormattedMessage } from 'react-intl';

import logoxinxing from '../images/xinxinglogo.svg'
import logoprovetsur from '../images/provetsurlogo.svg'
import logoneo from '../images/neologo.svg'
import logocleararch from '../images/cleararchlogo.jpg'
import logosubway from '../images/subwaylogo.jpg'
import logosweetbites from '../images/sweetbiteslogo.jpg'


const Nosotros = () => {

    function close() {
        document.querySelector("#popFormulario").classList.toggle("open");
    }

    /*

    setTimeout(() => {
        var h2Element = document.querySelectorAll('.nosotros h2');
        var spanElement = document.querySelectorAll('.nosotros h2 span');

        for (let i = 0; i < h2Element.length; i++) {
            var textoH2 = h2Element[i].textContent;
            var palabras = textoH2.split(' ');
            var nuevoContenido = '';
            palabras.forEach(function (palabra) {
                h2Element[i].classList.add('mostrar');
                nuevoContenido += '<span>' + palabra + '</span> ';
            });
            h2Element[i].innerHTML = nuevoContenido.trim();
        }
    }, 100);

    const targetRef = useRef(null);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5, // Umbral de visibilidad del 50%
        };

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setTimeout(() => {
                        var h2spanElement = document.querySelectorAll('.nosotros h2 span');
                        var tiempoTransicion = 70;
                        for (let i = 0; i < h2spanElement.length; i++) {
                            setTimeout(() => {
                                h2spanElement[i].classList.add('mostrar');
                            }, i * tiempoTransicion);
                        }
                    }, 100);

                    setTimeout(() => {
                        var h2activo = document.querySelectorAll('.nosotros h2');
                        for (let i = 0; i < h2activo.length; i++) {
                            h2activo[i].classList.add("active");
                        }
                    }, 1000);
                }
            });
        }, options);

        if (targetRef.current) {
            observer.observe(targetRef.current);
        }
        return () => {
            observer.disconnect();
        };
    }, []);
    */


    return <>
        <section className="nosotros" id="nosotros">
            <div className="container">
                <div className="part1">
                    <img src={nosotrosimg2} alt="" />
                </div>
                <div className="part2">
                    <h2>Who we are</h2>
                    <p>NERD STUDIO is the place where we master the delicate balance between creativity and strategy. This approach enables our meticulously crafted brands to genuinely resonate with people. Here, every piece of work is purposefully designed to solve a problem, and each innovative action we undertake is driven by a distinct purpose.</p>
                </div>
            </div>
            <div className='marquesina'>
                <Marquee speed={100}>
                    <div>
                        <img src={logoxinxing} alt="xinxing" />
                    </div>
                    <div>
                        <img src={logoprovetsur} alt="provetsur" />
                    </div>
                    <div>
                        <img src={logoneo} alt="neo-projects" />
                    </div>
                    <div>
                        <img src={logocleararch} alt="clear-arch-health" />
                    </div>
                    <div>
                        <img src={logosubway} alt="subway" />
                    </div>
                    <div>
                        <img src={logosweetbites} alt="sweet-bites" />
                    </div>
                </Marquee>
            </div>
        </section>
    </>
};

export default Nosotros;