import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import sweetBites from '../images/sweet.jpg'
import lamar from '../images/lamar.webp';
import yaw from '../images/yaw.jpg';
import wedding from '../images/wedding.webp';
import nerd from '../images/nerd2.jpg';
import neo from '../images/neo.jpg'
import clear from '../images/clear.jpg'
import clear2 from '../images/clear2.jpg'
import subway from '../images/subwayport.jpg'
import lamarport from '../images/lamarport.jpg'
import puntoazul from '../images/puntoazul.jpg'
import vet from '../images/vetsanmarcos.webp';
import chavin from '../images/chavin.webp';
import lamarWeb from '../images/lamarWeb.webp';
import provetsur from '../images/provetsur.webp';
import xinxingWeb from '../images/xinxingWeb3.jpg';
import touch from '../icons/touch.svg';
import { FormattedMessage } from 'react-intl';
import mouse from '../images/letstalk.png'
import $ from "jquery";
import { gsap } from "gsap";


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';


const Portafolio = () => {
    function change(e) {
        var nameCategory = e.target.getAttribute("category");
        var listSwiper = document.querySelectorAll(".portafolio .swiper")
        var itemsPortafolio = document.querySelectorAll(".itemsPortafolio .itemPortafolio")
        var listMenu = document.querySelectorAll(".portafolio .categoria ul li")

        for (let i = 0; i < listMenu.length; i++) {
            listMenu[i].classList.remove("active");
        }

        for (let i = 0; i < listMenu.length; i++) {
            if (nameCategory === listMenu[i].getAttribute("category")) {
                listMenu[i].classList.add("active");
            }
        }

        for (let i = 0; i < listSwiper.length; i++) {
            listSwiper[i].classList.add("ocultar");
            listSwiper[i].classList.remove("mostrar");
        }

        for (let i = 0; i < listSwiper.length; i++) {
            if (nameCategory === listSwiper[i].getAttribute("category")) {
                listSwiper[i].classList.add("mostrar");
                listSwiper[i].classList.remove("ocultar");
            }
        }
    }

    setTimeout(() => {
        var items = document.querySelectorAll('.itemPortafolio');
        for (var i = 0; i < items.length; i++) {
            //items[i].style.display = 'none';
            items[i].classList.add('ocultar');
        }

        const categoryMain = document.querySelector('.categoria li.active').getAttribute("category");
        if (categoryMain === "Todos") {
            for (var i = 0; i < items.length; i++) {
                if (i < 3) {
                    //items[i].style.display = 'block';
                    items[i].classList.remove('ocultar');
                } else {
                    //items[i].style.display = 'none';
                    items[i].classList.add('ocultar');
                }
            }
        }

        var itemsConCat = document.querySelectorAll(`.itemPortafolio.modulo-mas[category="${categoryMain}"]`);
        for (var i = 0; i < itemsConCat.length; i++) {
            if (i < 3) {
                //itemsConCat[i].style.display = 'block';
                itemsConCat[i].classList.remove('ocultar');
            } else {
                //itemsConCat[i].style.display = 'none';
                itemsConCat[i].classList.add('ocultar');
            }
        }

        const enlaces = document.querySelectorAll('.categoria li');
        enlaces.forEach((elemento) => {
            elemento.addEventListener('click', (evento) => {
                evento.preventDefault();
                enlaces.forEach((enlace) => enlace.classList.remove('active'));
                evento.target.classList.add('active');
                var categoria = evento.target.getAttribute("category");
                console.log(categoria);
                for (var i = 0; i < items.length; i++) {
                    //items[i].style.display = 'none';
                    items[i].classList.add('ocultar');
                }
                var cargarMasModulo = document.getElementById("cargarMasModulo");
                //cargarMasModulo.style.display = "block";
                cargarMasModulo.classList.remove('ocultar');
                var items2 = document.querySelectorAll('.itemPortafolio:not([category="' + categoria + '"])');
                for (var i = 0; i < items2.length; i++) {
                    //items2[i].style.display = 'none';
                    items2[i].classList.add('ocultar');
                }
                var items3 = document.querySelectorAll(`.itemPortafolio[category="${categoria}"]`);
                for (var i = 0; i < items3.length; i++) {
                    if (i < 3) {
                        //items3[i].style.display = 'block';
                        items3[i].classList.remove('ocultar');
                    }
                }
                if (categoria === "Todos") {
                    for (var i = 0; i < items.length; i++) {
                        if (i < 3) {
                            //items[i].style.display = 'block';
                            items[i].classList.remove('ocultar');
                        }
                    }
                } else {
                    var hiddenModulos2 = document.querySelectorAll(`.modulo-mas[category="${categoria}"].ocultar`);
                    if (hiddenModulos2.length === 0) {
                        cargarMasModulo.classList.add('ocultar');
                    }
                }
            })
        })
    }, 1000);

    function mas() {
        var cargarMasModulo = document.getElementById("cargarMasModulo");
        var categoryMain2 = document.querySelector('.categoria li.active').getAttribute("category");

        if (categoryMain2 === "Todos") {
            var hiddenModulos = document.querySelectorAll('.modulo-mas.ocultar');
            for (var i = 0; i < 3 && i < hiddenModulos.length; i++) {
                //hiddenModulos[i].style.display = 'block';
                hiddenModulos[i].classList.remove('ocultar');
            }

            if (document.querySelectorAll('.modulo-mas.ocultar').length === 0) {
                //cargarMasModulo.style.display = 'none';
                cargarMasModulo.classList.add('ocultar');
            }
        } else {
            var hiddenModulos = document.querySelectorAll(`.modulo-mas[category="${categoryMain2}"].ocultar`);
            for (var i = 0; i < 3 && i < hiddenModulos.length; i++) {
                //hiddenModulos[i].style.display = 'block';
                hiddenModulos[i].classList.remove('ocultar');
            }
            //cargarMasModulo.style.display = 'none';
            cargarMasModulo.classList.add('ocultar');
        }
    }


    useEffect(() => {
        const cursor = document.querySelector(".ht-cursor");
    
        document.body.addEventListener("mousemove", (e) => {
          gsap.to(cursor, {
            x: e.clientX,
            y: e.clientY,
          });
        });
    
        $("section.portafolio a").hover(
          function () {
            $(cursor).addClass("mostrar");
          },
          function () {
            $(cursor).removeClass("mostrar");
          }
        );
      }, []);



    return <>
        <section className="portafolio" id='portafolio'>
            <div className="ht-cursor">
    	        <div className="ht-cursor-text">Lets talk?</div>
            </div>
            <div className='container'>
                <h2><FormattedMessage id="portafolio_title" /></h2>
                <div className='categoria'>
                    <ul>
                        <li className='active' category='Todos' onClick={change} >All</li>
                        <li category='Brandingdesign' onClick={change} >Branding & design</li>
                        <li category='Desarrollo web' onClick={change} >Web design & Development</li>
                    </ul>
                </div>
                <div className='itemsPortafolio'>
                    <div className='itemPortafolio modulo-mas' category='Brandingdesign'>
                        <a href="#contactoPage">
                        <img className='touch' src={mouse} alt="click" />
                        <div>
                            <img src={sweetBites} alt="sweet Bites" />
                            <div className='filtro'></div>
                        </div>
                        <h3>Sweet Bites</h3>
                        <p>Branding  | Packanging</p>
                        </a>
                    </div>
                    <div className='itemPortafolio modulo-mas' category='Desarrollo web'>
                        <a href="#contactoPage">
                        <img className='touch' src={mouse} alt="click" />
                        <div>
                        <img src={wedding} alt="wedding" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Wedding Video Studio</h3>
                    <p>Web design  |  Development</p>
                    </a>
                    </div>
                    <div className='itemPortafolio modulo-mas' category='Desarrollo web'>
                        <a href="#contactoPage">
                        <img className='touch' src={mouse} alt="click" />
                        <div>
                        <img src={xinxingWeb} alt="xinxingWeb" />
                        <div className='filtro'></div>
                    </div>
                    <h3>XinXing</h3>
                    <p>Web design  |  Development</p>
                    </a>
                    </div>
                    <div className='itemPortafolio modulo-mas' category='Brandingdesign'>
                        <a href="#contactoPage">
                        <img className='touch' src={mouse} alt="click" />
                        <div>
                        <img src={yaw} alt="yaw" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Yaw</h3>
                    <p>Branding  | Packanging</p>
                    </a>
                    </div>
                    <div className='itemPortafolio modulo-mas' category='Desarrollo web'>
                        <a href="#contactoPage">
                        <img className='touch' src={mouse} alt="click" />
                        <div>
                        <img src={neo} alt="neo projects" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Neo Projects</h3>
                    <p>Branding | Web design | Development</p>
                    </a>
                    </div>
                    <div className='itemPortafolio modulo-mas' category='Brandingdesign'>
                        <a href="#contactoPage">
                        <img className='touch' src={mouse} alt="click" />
                        <div>
                        <img src={clear} alt="clear arch" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Clear Arch</h3>
                    <p>Print Design</p>
                    </a>
                    </div>
                    <div className='itemPortafolio modulo-mas' category='Brandingdesign'>
                        <a href="#contactoPage">
                        <img className='touch' src={mouse} alt="click" />
                        <div>
                        <img src={subway} alt="Subway" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Subway</h3>
                    <p>Branding</p>
                    </a>
                    </div>
                    <div className='itemPortafolio modulo-mas' category='Desarrollo web'>
                        <a href="#contactoPage">
                        <img className='touch' src={mouse} alt="click" />
                        <div>
                        <img src={lamarport} alt="consorcio lamar" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Consorcio Lamar</h3>
                    <p>Branding | Web design | Development</p>
                    </a>
                    </div>
                    <div className='itemPortafolio modulo-mas' category='Brandingdesign'>
                        <a href="#contactoPage">
                        <img className='touch' src={mouse} alt="click" />
                        <div>
                        <img src={clear2} alt="clear arch" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Clear Arch</h3>
                    <p>Branding</p>
                    </a>
                    </div>
                    <div className='itemPortafolio modulo-mas' category='Brandingdesign'>
                        <a href="#contactoPage">
                        <img className='touch' src={mouse} alt="click" />
                        <div>
                        <img src={puntoazul} alt="Punto azul" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Punto Azul</h3>
                    <p>Branding</p>
                    </a>
                    </div>
                </div>
                <div className='botones'>
                    <button className='mas' id='cargarMasModulo' onClick={mas}>Ver más proyectos</button>
                </div>
            </div>
            <Swiper
                category='Todos'
                paceBetween={60}
                slidesPerView={3.3}
                //autoplay={{ delay: 3000, disableOnInteraction: false, }}
                loop={true}
                //modules={[Autoplay, Pagination, Navigation]}
                breakpoints={{
                    1700: {
                        spaceBetween: 60,
                        slidesPerView: 3.3
                    },
                    1441: {
                        spaceBetween: 50,
                        slidesPerView: 3.3
                    },
                    1387: {
                        spaceBetween: 50,
                        slidesPerView: 3.3
                    },
                    1315: {
                        spaceBetween: 40,
                        slidesPerView: 3.05
                    },
                    1129: {
                        spaceBetween: 40,
                        slidesPerView: 2.6
                    },
                    1001: {
                        spaceBetween: 40,
                        slidesPerView: 2.5
                    },
                    934: {
                        spaceBetween: 40,
                        slidesPerView: 2.2
                    },
                    738: {
                        spaceBetween: 40,
                        slidesPerView: 1.8
                    },
                    636: {
                        spaceBetween: 40,
                        slidesPerView: 1.5
                    },
                    531: {
                        spaceBetween: 40,
                        slidesPerView: 1.3
                    },
                    501: {
                        spaceBetween: 40,
                        slidesPerView: 1.2
                    }
                }}
            >
                <SwiperSlide>
                <a href="#contactoPage">
                    <div>
                        <img src={sweetBites} alt="sweet Bites" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Sweet Bites</h3>
                    <p>Branding  | Packanging</p>
                </a>
                </SwiperSlide>
                <SwiperSlide>
                <a href="#contactoPage">
                    <div>
                        <img src={wedding} alt="wedding" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Wedding Video Studio</h3>
                    <p>Web design  |  Development</p>
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                <a href="#contactoPage">
                    <div>
                        <img src={xinxingWeb} alt="xinxingWeb" />
                        <div className='filtro'></div>
                    </div>
                    <h3>XinXing</h3>
                    <p>Web design  |  Development</p>
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                <a href="#contactoPage">
                    <div>
                        <img src={yaw} alt="yaw" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Yaw</h3>
                    <p>Branding  | Packanging</p>
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                <a href="#contactoPage">
                    <div>
                        <img src={neo} alt="neo projects" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Neo Projects</h3>
                    <p>Branding | Web design | Development</p>
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                <a href="#contactoPage">
                    <div>
                        <img src={clear} alt="clear arch" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Clear Arch</h3>
                    <p>Print Design</p>
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                <a href="#contactoPage">
                    <div>
                        <img src={subway} alt="Subway" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Subway</h3>
                    <p>Branding</p>
                    </a>
                </SwiperSlide>                
                <SwiperSlide>
                <a href="#contactoPage">
                    <div>
                        <img src={lamarport} alt="consorcio lamar" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Consorcio Lamar</h3>
                    <p>Branding | Web design | Development</p>
                    </a>
                </SwiperSlide>                
                <SwiperSlide>
                <a href="#contactoPage">
                    <div>
                        <img src={clear2} alt="clear arch" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Clear Arch</h3>
                    <p>Branding</p>
                    </a>
                </SwiperSlide>       
                <SwiperSlide>
                <a href="#contactoPage">
                    <div>
                        <img src={puntoazul} alt="Punto azul" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Punto Azul</h3>
                    <p>Branding</p>
                    </a>
                </SwiperSlide>    
            </Swiper>

            <Swiper
                category='Brandingdesign'
                className='ocultar'
                spaceBetween={60}
                slidesPerView={3.3}
                autoplay={{ delay: 3000, disableOnInteraction: false, }}
                loop={true}
                modules={[Autoplay, Pagination, Navigation]}
                breakpoints={{
                    1700: {
                        spaceBetween: 60,
                        slidesPerView: 3.3
                    },
                    1441: {
                        spaceBetween: 50,
                        slidesPerView: 3.3
                    },
                    1387: {
                        spaceBetween: 50,
                        slidesPerView: 3.3
                    },
                    1315: {
                        spaceBetween: 40,
                        slidesPerView: 3.05
                    },
                    1129: {
                        spaceBetween: 40,
                        slidesPerView: 2.6
                    },
                    1001: {
                        spaceBetween: 40,
                        slidesPerView: 2.5
                    },
                    934: {
                        spaceBetween: 40,
                        slidesPerView: 2.2
                    },
                    738: {
                        spaceBetween: 40,
                        slidesPerView: 1.8
                    },
                    636: {
                        spaceBetween: 40,
                        slidesPerView: 1.5
                    },
                    531: {
                        spaceBetween: 40,
                        slidesPerView: 1.3
                    },
                    501: {
                        spaceBetween: 40,
                        slidesPerView: 1.2
                    }
                }}
            >
                <SwiperSlide>
                    <a href="#contactoPage">
                    <div>
                        <img src={sweetBites} alt="sweet Bites" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Sweet Bites</h3>
                    <p>Branding  | Packanging</p>
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="#contactoPage">
                    <div>
                        <img src={yaw} alt="yaw" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Yaw</h3>
                    <p>Branding  | Packanging</p>
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="#contactoPage">
                    <div>
                        <img src={neo} alt="neo projects" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Neo Projects</h3>
                    <p>Branding | Web design | Development</p>
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="#contactoPage">
                    <div>
                        <img src={clear} alt="clear arch" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Clear Arch</h3>
                    <p>Print Design</p>
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="#contactoPage">
                    <div>
                        <img src={subway} alt="Subway" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Subway</h3>
                    <p>Branding</p>
                    </a>
                </SwiperSlide>   
                <SwiperSlide>
                    <a href="#contactoPage">
                    <div>
                        <img src={lamarport} alt="consorcio lamar" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Consorcio Lamar</h3>
                    <p>Branding | Web design | Development</p>
                    </a>
                </SwiperSlide>                
                <SwiperSlide>
                    <a href="#contactoPage">
                    <div>
                        <img src={clear2} alt="clear arch" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Clear Arch</h3>
                    <p>Branding</p>
                    </a>
                </SwiperSlide>       
                <SwiperSlide>
                    <a href="#contactoPage">
                    <div>
                        <img src={puntoazul} alt="Punto azul" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Punto Azul</h3>
                    <p>Branding</p>
                    </a>
                </SwiperSlide>
            </Swiper>

            <Swiper
                category='Desarrollo web'
                className='ocultar'
                spaceBetween={60}
                slidesPerView={3.3}
                autoplay={{ delay: 3000, disableOnInteraction: false, }}
                loop={true}
                modules={[Autoplay, Pagination, Navigation]}
                breakpoints={{
                    1700: {
                        spaceBetween: 60,
                        slidesPerView: 3.3
                    },
                    1441: {
                        spaceBetween: 50,
                        slidesPerView: 3.3
                    },
                    1387: {
                        spaceBetween: 50,
                        slidesPerView: 3.3
                    },
                    1315: {
                        spaceBetween: 40,
                        slidesPerView: 3.05
                    },
                    1129: {
                        spaceBetween: 40,
                        slidesPerView: 2.6
                    },
                    1001: {
                        spaceBetween: 40,
                        slidesPerView: 2.5
                    },
                    934: {
                        spaceBetween: 40,
                        slidesPerView: 2.2
                    },
                    738: {
                        spaceBetween: 40,
                        slidesPerView: 1.8
                    },
                    636: {
                        spaceBetween: 40,
                        slidesPerView: 1.5
                    },
                    531: {
                        spaceBetween: 40,
                        slidesPerView: 1.3
                    },
                    501: {
                        spaceBetween: 40,
                        slidesPerView: 1.2
                    }
                }}
            >
                <SwiperSlide>
                    <a href="#contactoPage">
                    <div>
                        <img src={wedding} alt="wedding" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Wedding Video Studio</h3>
                    <p>Web design  |  Development</p>
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="#contactoPage">
                    <div>
                        <img src={xinxingWeb} alt="xinxingWeb" />
                        <div className='filtro'></div>
                    </div>
                    <h3>XinXing</h3>
                    <p>Web design  |  Development</p>
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="#contactoPage">
                    <div>
                        <img src={neo} alt="neo projects" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Neo Projects</h3>
                    <p>Branding | Web design | Development</p>
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="#contactoPage">
                    <div>
                        <img src={lamarport} alt="consorcio lamar" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Consorcio Lamar</h3>
                    <p>Branding | Web design | Development</p>
                    </a>
                </SwiperSlide>  
                <SwiperSlide>
                    <a href="#contactoPage">
                    <div>
                        <img src={wedding} alt="wedding" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Wedding Video Studio</h3>
                    <p>Web design  |  Development</p>
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="#contactoPage">
                    <div>
                        <img src={xinxingWeb} alt="xinxingWeb" />
                        <div className='filtro'></div>
                    </div>
                    <h3>XinXing</h3>
                    <p>Web design  |  Development</p>
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="#contactoPage">
                    <div>
                        <img src={neo} alt="neo projects" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Neo Projects</h3>
                    <p>Branding | Web design | Development</p>
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="#contactoPage">
                    <div>
                        <img src={lamarport} alt="consorcio lamar" />
                        <div className='filtro'></div>
                    </div>
                    <h3>Consorcio Lamar</h3>
                    <p>Branding | Web design | Development</p>
                    </a>
                </SwiperSlide>
            </Swiper>
            
            
        </section>
    </>
};

export default Portafolio;