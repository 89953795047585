import React from 'react';
import cabeza from '../icons/personaje.svg';
import whatsapp from '../icons/wtp.svg';
import figura1 from '../icons/bola.svg';
import figura2 from '../icons/circulo.svg';
import figura3 from '../icons/calltoaction_3.svg';
import imgPortafolio from '../icons/maletin.svg';
import laptop from '../icons/laptop.svg';
import lapis from '../icons/lapis.svg';
import closex from '../icons/close.svg';
import logo from '../icons/logo-atenas.svg';
import { FormattedMessage } from 'react-intl';
import facebook from '../icons/facebook2.svg'
import instagram from '../icons/instagram2.svg'
import whatsapp2 from '../icons/whatsapp2.svg'
import flechahome from '../icons/flechahome.svg'
import imgClose from '../icons/close-x2.svg'

const Home = () => {

    const submitFormHome = (e) => {
        e.preventDefault();
        var valorFullNameHome = 0;
        var valortelephoneHome = 0;
        var valoremailHome = 0;
        var valormessageHome = 0;
        var valorterminosHome = 0;

        if (document.querySelector("#full-name-home").value.length > 0) {
            valorFullNameHome = 1;
            document.querySelector("#full-name-home").classList.remove("error");
        } else {
            document.querySelector("#full-name-home").classList.add("error");
        }

        if (document.querySelector("#telephone-home").value.length > 0) {
            valortelephoneHome = 1;
            document.querySelector("#telephone-home").classList.remove("error");
        } else {
            document.querySelector("#telephone-home").classList.add("error");
        }

        if (document.querySelector("#mail-home").value.length > 0) {
            valoremailHome = 1;
            document.querySelector("#mail-home").classList.remove("error");
        } else {
            document.querySelector("#mail-home").classList.add("error");
        }

        if (document.querySelector("#message-home").value.length > 0) {
            valormessageHome = 1;
            document.querySelector("#message-home").classList.remove("error");
        } else {
            document.querySelector("#message-home").classList.add("error");
        }

        /*

        if (document.querySelector("#terminos-home").checked) {
            valorterminosHome = 1;
            document.querySelector("#terminos-home").classList.remove("error");
        } else {
            document.querySelector("#terminos-home").classList.add("error");
        }
        */

        var result = valorFullNameHome * valortelephoneHome * valoremailHome * valormessageHome;

        if (result == 0) {
            e.preventDefault();
        } else {
            document.querySelector("#fs-frm-home").submit();
        }

    }

    const submitFormHomePop = (e) => {
        e.preventDefault();
        var valorFullNameHomePop = 0;
        var valortelephoneHomePop = 0;
        var valoremailHomePop = 0;
        var valormessageHomePop = 0;
        var valorterminosHomePop = 0;

        if (document.querySelector("#full-name-home-pop").value.length > 0) {
            valorFullNameHomePop = 1;
            document.querySelector("#full-name-home-pop").classList.remove("error");
        } else {
            document.querySelector("#full-name-home-pop").classList.add("error");
        }

        if (document.querySelector("#telephone-home-pop").value.length > 0) {
            valortelephoneHomePop = 1;
            document.querySelector("#telephone-home-pop").classList.remove("error");
        } else {
            document.querySelector("#telephone-home-pop").classList.add("error");
        }

        if (document.querySelector("#mail-home-pop").value.length > 0) {
            valoremailHomePop = 1;
            document.querySelector("#mail-home-pop").classList.remove("error");
        } else {
            document.querySelector("#mail-home-pop").classList.add("error");
        }
        if (document.querySelector("#message-home-pop").value.length > 0) {
            valormessageHomePop = 1;
            document.querySelector("#message-home-pop").classList.remove("error");
        } else {
            document.querySelector("#message-home-pop").classList.add("error");
        }
        /*
        if (document.querySelector("#terminos-home-pop").checked) {
            valorterminosHomePop = 1;
            document.querySelector("#terminos-home-pop").classList.remove("error");
        } else {
            document.querySelector("#terminos-home-pop").classList.add("error");
        }
        */

        var result = valorFullNameHomePop * valortelephoneHomePop * valoremailHomePop * valormessageHomePop;

        if (result == 0) {
            e.preventDefault();
        } else {
            document.querySelector("#fs-frm-home").submit();
        }
    }

    window.addEventListener('click', function (e) {
        if (e.target.closest("#full-name-home")) {
            document.querySelector("#full-name-home").closest('.form-group').classList.add("focusin");
        } else {
            document.querySelector("#full-name-home").closest('.form-group').classList.remove("focusin");
            if (document.querySelector("#full-name-home").value.length > 0) {
                document.querySelector("#full-name-home").closest('.form-group').classList.add("ok");
            } else {
                document.querySelector("#full-name-home").closest('.form-group').classList.remove("ok");
            }
        }
        if (e.target.closest("#full-name-home-pop")) {
            document.querySelector("#full-name-home-pop").closest('.form-group').classList.add("focusin");
        } else {
            document.querySelector("#full-name-home-pop").closest('.form-group').classList.remove("focusin");
            if (document.querySelector("#full-name-home-pop").value.length > 0) {
                document.querySelector("#full-name-home-pop").closest('.form-group').classList.add("ok");
            } else {
                document.querySelector("#full-name-home-pop").closest('.form-group').classList.remove("ok");
            }
        }
        if (e.target.closest("#telephone-home")) {
            document.querySelector("#telephone-home").closest('.form-group').classList.add("focusin");
        } else {
            document.querySelector("#telephone-home").closest('.form-group').classList.remove("focusin");
            if (document.querySelector("#telephone-home").value.length > 0) {
                document.querySelector("#telephone-home").closest('.form-group').classList.add("ok");
            } else {
                document.querySelector("#telephone-home").closest('.form-group').classList.remove("ok");
            }
        }
        if (e.target.closest("#telephone-home-pop")) {
            document.querySelector("#telephone-home-pop").closest('.form-group').classList.add("focusin");
        } else {
            document.querySelector("#telephone-home-pop").closest('.form-group').classList.remove("focusin");
            if (document.querySelector("#telephone-home-pop").value.length > 0) {
                document.querySelector("#telephone-home-pop").closest('.form-group').classList.add("ok");
            } else {
                document.querySelector("#telephone-home-pop").closest('.form-group').classList.remove("ok");
            }
        }
        if (e.target.closest("#mail-home")) {
            document.querySelector("#mail-home").closest('.form-group').classList.add("focusin");
        } else {
            document.querySelector("#mail-home").closest('.form-group').classList.remove("focusin");
            if (document.querySelector("#mail-home").value.length > 0) {
                document.querySelector("#mail-home").closest('.form-group').classList.add("ok");
            } else {
                document.querySelector("#mail-home").closest('.form-group').classList.remove("ok");
            }
        }
        if (e.target.closest("#mail-home-pop")) {
            document.querySelector("#mail-home-pop").closest('.form-group').classList.add("focusin");
        } else {
            document.querySelector("#mail-home-pop").closest('.form-group').classList.remove("focusin");
            if (document.querySelector("#mail-home-pop").value.length > 0) {
                document.querySelector("#mail-home-pop").closest('.form-group').classList.add("ok");
            } else {
                document.querySelector("#mail-home-pop").closest('.form-group').classList.remove("ok");
            }
        }

        if (e.target.closest("#message-home")) {
            document.querySelector("#message-home").closest('.form-group').classList.add("focusin");
        } else {
            document.querySelector("#message-home").closest('.form-group').classList.remove("focusin");
            if (document.querySelector("#message-home").value.length > 0) {
                document.querySelector("#message-home").closest('.form-group').classList.add("ok");
            } else {
                document.querySelector("#message-home").closest('.form-group').classList.remove("ok");
            }
        }
        if (e.target.closest("#message-home-pop")) {
            document.querySelector("#message-home-pop").closest('.form-group').classList.add("focusin");
        } else {
            document.querySelector("#message-home-pop").closest('.form-group').classList.remove("focusin");
            if (document.querySelector("#message-home-pop").value.length > 0) {
                document.querySelector("#message-home-pop").closest('.form-group').classList.add("ok");
            } else {
                document.querySelector("#message-home-pop").closest('.form-group').classList.remove("ok");
            }
        }
    })

    document.addEventListener("keydown", function (event) {
        if (event.keyCode == 9) {
            document.querySelector("#full-name-home").addEventListener('focusin', (event) => {
                document.querySelector("#full-name-home").closest('.form-group').classList.add("focusin");
            });
            document.querySelector("#full-name-home").addEventListener('focusout', (event) => {
                document.querySelector("#full-name-home").closest('.form-group').classList.remove("focusin");
                if (document.querySelector("#full-name-home").value.length > 0) {
                    document.querySelector("#full-name-home").closest('.form-group').classList.add("ok");
                } else {
                    document.querySelector("#full-name-home").closest('.form-group').classList.remove("ok");
                }
            });

            document.querySelector("#full-name-home-pop").addEventListener('focusin', (event) => {
                document.querySelector("#full-name-home-pop").closest('.form-group').classList.add("focusin");
            });
            document.querySelector("#full-name-home-pop").addEventListener('focusout', (event) => {
                document.querySelector("#full-name-home-pop").closest('.form-group').classList.remove("focusin");
                if (document.querySelector("#full-name-home-pop").value.length > 0) {
                    document.querySelector("#full-name-home-pop").closest('.form-group').classList.add("ok");
                } else {
                    document.querySelector("#full-name-home-pop").closest('.form-group').classList.remove("ok");
                }
            });

            document.querySelector("#telephone-home").addEventListener('focusin', (event) => {
                document.querySelector("#telephone-home").closest('.form-group').classList.add("focusin");
            });
            document.querySelector("#telephone-home").addEventListener('focusout', (event) => {
                document.querySelector("#telephone-home").closest('.form-group').classList.remove("focusin");
                if (document.querySelector("#telephone-home").value.length > 0) {
                    document.querySelector("#telephone-home").closest('.form-group').classList.add("ok");
                } else {
                    document.querySelector("#full-name-home").closest('.form-group').classList.remove("ok");
                }
            });

            document.querySelector("#telephone-home-pop").addEventListener('focusin', (event) => {
                document.querySelector("#telephone-home-pop").closest('.form-group').classList.add("focusin");
            });
            document.querySelector("#telephone-home-pop").addEventListener('focusout', (event) => {
                document.querySelector("#telephone-home-pop").closest('.form-group').classList.remove("focusin");
                if (document.querySelector("#telephone-home-pop").value.length > 0) {
                    document.querySelector("#telephone-home-pop").closest('.form-group').classList.add("ok");
                } else {
                    document.querySelector("#full-name-home-pop").closest('.form-group').classList.remove("ok");
                }
            });

            document.querySelector("#mail-home").addEventListener('focusin', (event) => {
                document.querySelector("#mail-home").closest('.form-group').classList.add("focusin");
            });
            document.querySelector("#mail-home").addEventListener('focusout', (event) => {
                document.querySelector("#mail-home").closest('.form-group').classList.remove("focusin");
                if (document.querySelector("#mail-home").value.length > 0) {
                    document.querySelector("#mail-home").closest('.form-group').classList.add("ok");
                } else {
                    document.querySelector("#mail-home").closest('.form-group').classList.remove("ok");
                }
            });

            document.querySelector("#mail-home-pop").addEventListener('focusin', (event) => {
                document.querySelector("#mail-home-pop").closest('.form-group').classList.add("focusin");
            });
            document.querySelector("#mail-home-pop").addEventListener('focusout', (event) => {
                document.querySelector("#mail-home-pop").closest('.form-group').classList.remove("focusin");
                if (document.querySelector("#mail-home-pop").value.length > 0) {
                    document.querySelector("#mail-home-pop").closest('.form-group').classList.add("ok");
                } else {
                    document.querySelector("#mail-home-pop").closest('.form-group').classList.remove("ok");
                }
            });

            document.querySelector("#message-home").addEventListener('focusin', (event) => {
                document.querySelector("#message-home").closest('.form-group').classList.add("focusin");
            });
            document.querySelector("#message-home").addEventListener('focusout', (event) => {
                document.querySelector("#message-home").closest('.form-group').classList.remove("focusin");
                if (document.querySelector("#message-home").value.length > 0) {
                    document.querySelector("#message-home").closest('.form-group').classList.add("ok");
                } else {
                    document.querySelector("#message-home").closest('.form-group').classList.remove("ok");
                }
            });

            document.querySelector("#message-home-pop").addEventListener('focusin', (event) => {
                document.querySelector("#message-home-pop").closest('.form-group').classList.add("focusin");
            });
            document.querySelector("#message-home-pop").addEventListener('focusout', (event) => {
                document.querySelector("#message-home-pop").closest('.form-group').classList.remove("focusin");
                if (document.querySelector("#message-home-pop").value.length > 0) {
                    document.querySelector("#message-home-pop").closest('.form-group').classList.add("ok");
                } else {
                    document.querySelector("#message-home-pop").closest('.form-group').classList.remove("ok");
                }
            });
        }
    })

    function handleChange() {
        console.log('asasdasd');
        if (document.querySelector("#full-name-home").value.length > 0) {
            document.querySelector("#full-name-home").closest('.form-group').classList.add("ok");
        } else {
            document.querySelector("#full-name-home").closest('.form-group').classList.remove("ok");
        }
        if (document.querySelector("#full-name-home-pop").value.length > 0) {
            document.querySelector("#full-name-home-pop").closest('.form-group').classList.add("ok");
        } else {
            document.querySelector("#full-name-home-pop").closest('.form-group').classList.remove("ok");
        }
        if (document.querySelector("#telephone-home").value.length > 0) {
            document.querySelector("#telephone-home").closest('.form-group').classList.add("ok");
        } else {
            document.querySelector("#telephone-home").closest('.form-group').classList.remove("ok");
        }
        if (document.querySelector("#telephone-home-pop").value.length > 0) {
            document.querySelector("#telephone-home-pop").closest('.form-group').classList.add("ok");
        } else {
            document.querySelector("#telephone-home-pop").closest('.form-group').classList.remove("ok");
        }
        if (document.querySelector("#mail-home").value.length > 0) {
            document.querySelector("#mail-home").closest('.form-group').classList.add("ok");
        } else {
            document.querySelector("#mail-home").closest('.form-group').classList.remove("ok");
        }
        if (document.querySelector("#mail-home-pop").value.length > 0) {
            document.querySelector("#mail-home-pop").closest('.form-group').classList.add("ok");
        } else {
            document.querySelector("#mail-home-pop").closest('.form-group').classList.remove("ok");
        }
        if (document.querySelector("#message-home").value.length > 0) {
            document.querySelector("#message-home").closest('.form-group').classList.add("ok");
        } else {
            document.querySelector("#message-home").closest('.form-group').classList.remove("ok");
        }
        if (document.querySelector("#message-home-pop").value.length > 0) {
            document.querySelector("#message-home-pop").closest('.form-group').classList.add("ok");
        } else {
            document.querySelector("#message-home-pop").closest('.form-group').classList.remove("ok");
        }
    }

    function close() {
        document.querySelector("#popFormulario").classList.toggle("open");
        document.querySelector("body").classList.toggle("scrollHidden");
    }

    function distorcion() {
        var listaImagenes = document.querySelectorAll(".home .imageDistorcion");
        var tiempoTransicion = 70;

        for (let i = 0; i < listaImagenes.length; i++) {
            setTimeout(() => {
                listaImagenes[i].style.opacity = 1;
            }, i * tiempoTransicion);
            if (i !== listaImagenes.length - 1) {
                setTimeout(() => {
                    listaImagenes[i + 1].style.opacity = 1;
                    setTimeout(() => {
                        listaImagenes[i].style.opacity = 0;
                    }, (i) * 20);
                }, (i + 0.2) * tiempoTransicion);
            } else {
                listaImagenes[i].style.opacity = 0;
            }
        }
    }

    setTimeout(() => {
        var listaSpan = document.querySelectorAll(".home .part1 h1 .contentAnimation span");

        var tiempoTransicion2 = 70;

        for (let i = 0; i < listaSpan.length; i++) {
            setTimeout(() => {
                listaSpan[i].classList.add("active");
                setTimeout(() => {
                    listaSpan[i].classList.add("active");
                }, (i) * 20);
            }, (i + 0.2) * tiempoTransicion2);
        }
    }, 100);

    setTimeout(() => {
        var listaSpan = document.querySelectorAll(".home .part1 h1 .contentAnimation span");

        for (let i = 0; i < listaSpan.length; i++) {
            listaSpan[i].classList.add("notAnimation");
        }
    }, 1000);

    return <>
        <a href='https://wa.link/h6hpnr' target='_blank' className='btn_whatsapp'><img src={whatsapp} alt="img1" /></a>

        <div className='popFormulario' id="popFormulario">
            <div className='container'>
                <div className='formulario'>
                    <button className='close' onClick={close}><img src={imgClose} alt="" /></button>
                    <h1>Got a project in mind?</h1>
                    <p>Contact us and we will respond to you promptly.</p>
                    <form acceptCharset="utf-8" id='fs-frm-pop' action="php/thanks.php" method="post">
                        <fieldset>
                            <div className="form-group">
                                <input type="text" name="fullname" autoComplete='off' id='full-name-home-pop' placeholder="" className="form-input" onChange={handleChange} />
                                <label htmlFor="full-name-home-pop" className="form-label"><FormattedMessage id="home_contacto_label_nombre" /></label>
                            </div>
                            <div className="form-group">
                                <input type="mail" name="email" autoComplete='off' id='mail-home-pop' placeholder="" className="form-input" onChange={handleChange} />
                                <label htmlFor="mail-home-pop" className="form-label"><FormattedMessage id="home_contacto_label_Email" /></label>
                            </div>
                            <div className="form-group">
                                <input type="number" name="celular" autoComplete='off' id='telephone-home-pop' placeholder="" className="form-input" onChange={handleChange} />
                                <label htmlFor="telephone-home-pop" className="form-label"><FormattedMessage id="home_contacto_label_Celular" /></label>
                            </div>
                            <div className="form-group">
                                <textarea class="form-input" name="message" id="message-home-pop" placeholder="" rows="3" onChange={handleChange}></textarea>
                                <label htmlFor="message-home-pop" className="form-label">Message</label>
                            </div>
                            <button className="submit" onClick={submitFormHomePop}><FormattedMessage id="home_contacto_label_enviar" /></button>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
        <div className='menuCel'>
            <div><a href='#portafolio'><img src={imgPortafolio} alt="" />Portfolio</a></div>
            <div><a href='#servicios'><img src={laptop} alt="" />Services</a></div>
            <div><button onClick={close}><img src={lapis} alt="" />Contact</button></div>
        </div>
        <section className="home" id="home">
            <div className='container'>
                <div className='content'>
                    <div className='part1'>
                        <h1 className='mensajeIngles'>Design <br /> Agency</h1>
                        <h1 className='ocultar mensajeEspanol'>Agencia <br />de Diseño</h1>
                        <p>Creative minds delivering brand strategy, identity design, print/digital design and web design for impactful digital experiences.</p>
                        <button onClick={close}>Lets Talk</button>
                        <img src={cabeza} alt="" className='responsivepersonaje' />
                    </div>
                    <div className='part2'>
                        <div className='formulario' >
                            <h1>Got a project in mind?</h1>
                            <p>Contact us and we will respond to you promptly.</p>
                            <form acceptCharset="utf-8" id='fs-frm-home' action="php/thanks.php" method="post">
                                <fieldset>
                                    <div className="form-group">
                                        <input type="text" name="fullname" autoComplete='off' id='full-name-home' placeholder="" className="form-input" onChange={handleChange} />
                                        <label htmlFor="full-name-home" className="form-label"><FormattedMessage id="home_contacto_label_nombre" /></label>
                                    </div>
                                    <div className="form-group-row">
                                        <div className="form-group">
                                            <input type="mail" name="email" autoComplete='off' id='mail-home' placeholder="" className="form-input" onChange={handleChange} />
                                            <label htmlFor="mail-home" className="form-label"><FormattedMessage id="home_contacto_label_Email" /></label>
                                        </div>
                                        <div className="form-group">
                                            <input type="number" name="celular" autoComplete='off' id='telephone-home' placeholder="" className="form-input" onChange={handleChange} />
                                            <label htmlFor="telephone-home" className="form-label"><FormattedMessage id="home_contacto_label_Celular" /></label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <textarea class="form-input" name="message" id="message-home" placeholder="" rows="3" onChange={handleChange}></textarea>
                                        <label htmlFor="message-home" className="form-label">Message</label>
                                    </div>
                                    <div class="buttonColor">
                                        <button className="submit" onClick={submitFormHome}><FormattedMessage id="home_contacto_label_enviar" /></button>
                                    </div>
                                </fieldset>
                            </form>
                            <div className='cabezafloat' onMouseEnter={distorcion}>
                                <img src={cabeza} alt="img2" className='imageDistorcion img1' />
                                <img src={cabeza} alt="img2" className='imageDistorcion img2' />
                                <img src={cabeza} alt="img2" className='imageDistorcion img3' />
                                <img src={cabeza} alt="img2" className='imageDistorcion img4' />
                                <img src={cabeza} alt="img2" className='imageDistorcion img5' />
                                <img src={cabeza} alt="img2" className='imageDistorcion img6' />
                                <img src={cabeza} alt="img2" className='imageDistorcion img7' />
                                <img src={cabeza} alt="img2" className='imageDistorcion img8' />
                                <img src={cabeza} alt="img2" className='imageDistorcion img9' />
                                <img src={cabeza} alt="img2" className='imageDistorcion img10' />
                            </div>
                        </div>
                    </div>
                </div>
                <img src={figura1} alt="" className='responsive img1' />
                <img src={figura1} alt="" className='responsive img2' />
                <img src={figura2} alt="" className='responsive img3' />
                <img src={figura3} alt="" className='responsive img4' />
            </div>
            <img src={flechahome} alt="flecha" className='flechahome' />
        </section >
    </>
};

export default Home;