import React, { useEffect, useRef } from 'react';
import icono1 from '../icons/icono1.svg';
import icono1solo from '../icons/icono1solo.svg';
import icono2 from '../icons/icono2.svg';
import icono2solo from '../icons/icono2solo.svg';
import flecharoja from '../icons/flecha-roja.svg';
import flechaNegra from '../icons/flechita2.svg'
import { FormattedMessage } from 'react-intl';

const Portafolio = () => {

    function Mostrar(e) {
        console.log(e.target);
        e.target.parentElement.classList.toggle("mostrar");
    }

    function Mostrar2(e) {
        //e.target.parentElement.classList.toggle("abierto");
        var elementos = document.querySelectorAll("section.servicios .container .item-services .img_services");
        if (e.target.parentElement.classList.contains("abierto")) {
            console.log("asd");
            e.target.parentElement.classList.remove("abierto");
        } else {
            for (let i = 0; i < elementos.length; i++) {
                elementos[i].parentElement.classList.remove("abierto");
            }
            e.target.parentElement.classList.add("abierto");
        }
    }

    /*

    setTimeout(() => {
        var h2Element = document.querySelectorAll('.servicios h2');
        var spanElement = document.querySelectorAll('.servicios h2 span');

        for (let i = 0; i < h2Element.length; i++) {
            var textoH2 = h2Element[i].textContent;
            var palabras = textoH2.split(' ');
            var nuevoContenido = '';
            palabras.forEach(function (palabra) {
                h2Element[i].classList.add('mostrar');
                nuevoContenido += '<span>' + palabra + '</span> ';
            });
            h2Element[i].innerHTML = nuevoContenido.trim();
        }
    }, 100);

    const targetRef = useRef(null);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5, // Umbral de visibilidad del 50%
        };

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setTimeout(() => {
                        var h2spanElement = document.querySelectorAll('.servicios h2 span');
                        var tiempoTransicion = 70;
                        for (let i = 0; i < h2spanElement.length; i++) {
                            setTimeout(() => {
                                h2spanElement[i].classList.add('mostrar');
                            }, i * tiempoTransicion);
                        }
                    }, 100);

                    setTimeout(() => {
                        var h2activo = document.querySelectorAll('.servicios h2');
                        for (let i = 0; i < h2activo.length; i++) {
                            h2activo[i].classList.add("active");
                        }
                    }, 1000);
                }
            });
        }, options);

        if (targetRef.current) {
            observer.observe(targetRef.current);
        }
        return () => {
            observer.disconnect();
        };
    }, []);

    */

    return <>
        <section className="servicios" id='servicios'>
            <div className='container'>
                <h2><FormattedMessage id="servicios_title" /></h2>
                <div className='listServices'>
                    <div className="item-services">
                        <div className="img_services" onClick={Mostrar2}>
                            <div className='imgIcon'>
                                <img src={icono1solo} alt="logo" />
                            </div>
                            <h3>Branding & Design</h3>
                            <img src={flechaNegra} alt="flecha" className='flecha' />
                        </div>
                        <div className="info_services">
                            <ul>
                                <li>Naming</li>
                                <li>Visual Identity</li>
                                <li>Packaging Design</li>
                                <li>Brochures</li>
                                <li>Rebranding Services</li>
                                <li>Illustration Services</li>
                                <li>Social Media Graphics</li>
                                <li>Merchandise Design</li>
                                <li>Video Graphics and Animation</li>
                            </ul>
                            <div className='texto'>
                                <p><FormattedMessage id="servicios_servicio1_parrafo" /></p>
                            </div>
                            <button onClick={Mostrar}><img src={flecharoja} alt="flecha" /><span className='español'><FormattedMessage id="servicios_button" /></span><span className='ingles'><FormattedMessage id="servicios_button2" /></span></button>
                        </div>
                    </div>
                    <div className="item-services">
                        <div className="img_services" onClick={Mostrar2}>
                            <div className='imgIcon'>
                                <img src={icono2solo} alt="logo" />
                            </div>
                            <h3>Web design & Development</h3>
                            <img src={flechaNegra} alt="flecha" className='flecha' />
                        </div>
                        <div className="info_services">
                            <ul>
                                <li>Front-End Web Design</li>
                                <li>Back-End & CMS Development</li>
                                <li>E-Commerce</li>
                                <li>Mobile App Experience Design</li>
                                <li>Custom Web Applications</li>
                                <li>Web Design applicatios</li>
                                <li>UX/UI Design Optimization</li>                                
                            </ul>
                            <div className='texto'>
                                <p><FormattedMessage id="servicios_servicio2_parrafo" /></p>
                            </div>
                            <button onClick={Mostrar}><img src={flecharoja} alt="flecha" /><span className='español'><FormattedMessage id="servicios_button" /></span><span className='ingles'><FormattedMessage id="servicios_button2" /></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default Portafolio;